













































































































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import {validationMixin} from "vuelidate";
import {email, helpers, maxLength, minLength, required} from "vuelidate/lib/validators";
import {LoginDTO} from "@/types/ExtraTypes";
import firebase from "firebase";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

import {Configuration, ProfileApiFactory} from "@shared_vue/openapi/myvaultapi";
import {UserManagerApiFactory} from "@shared_vue/openapi/userapi";
import {Configuration as UserConfiguration} from "@shared_vue/openapi/userapi/configuration"
import Cookies from 'js-cookie'
import ResetModal from "@/views/widgets/modals/ResetModal.vue";
import OkModal from "@/views/widgets/modals/OkModal.vue";

@Component({
  components: { ResetModal, OkModal },
  mixins: [validationMixin],
  validations: {
    form: {
      username: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
})

export default class TheHeader extends Vue {
  private ui = vxm.ui;
  private auth = vxm.auth;
  private darkMode: boolean = false;
  private form = this.getEmptyForm();
  private submitted = false;
  private showOk = false;
  private showOk2 = false;
  private hover = false;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private provider: GoogleAuthProvider | undefined;
  private profileApi = ProfileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
  private userApi = UserManagerApiFactory(<UserConfiguration>{basePath:process.env.VUE_APP_USER_API_URL},process.env.VUE_APP_USER_API_URL);
  private cookied: boolean = false;
  private showPasswordReset = false;
  private usermail = '';
  private toggleDarkMode(){
    this.ui.toggleDarkMode();
  }
  private async passRequest(){
    console.log('passrequest:' + this.usermail)
    try {
      this.auth.setCheckingUser(true);
      await this.userApi.userManagerPasswordReset({email: this.usermail});
      this.showOk = true;
    } catch (e) {
      alert(`ERROR: ${e}`);
    } finally {
      this.auth.setCheckingUser(false);
    }
  }
  private forgotPassFlow(){
    this.showPasswordReset = true;
  }
  googleProvider() {
    // [START auth_google_provider_create]
    this.provider = new firebase.auth.GoogleAuthProvider();
    // [END auth_google_provider_create]

    // [START auth_google_provider_scopes]
    this.provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    this.provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    // this.provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
    // [END auth_google_provider_scopes]


  }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean){
    //redirect
    //NB this is not so simple anymore. We must check the state of the user
    //this.$router.push('/dashboard');
    const outerThis = this;
    console.log('id token. do what now?')
    this.profileApi.profileGetProfile().then(resp => {
      console.log(resp.data);
      //add version stuff here :-O
      if (resp.status==200&&resp.data&&resp.data.userStatus){
        if (resp.data.userStatus==1){
          console.log('ok this user is legit')
          this.$router.push('/dashboard');
        }
      }

    }).catch(function (error) {
      if (error) {
        console.log(JSON.stringify(error))
        if (error.message.includes('404')||error.message.includes('403')) { //user signed in with google but does not exist
          console.log('needs to register!') //NB he will exist on firebase now and have a guid
          outerThis.userApi.userManagerRegisterFromGoogle().then(res2=>{
            //need user's cell
            if (res2.status==200){
              outerThis.ui.registerState=4;
              outerThis.ui.asideShow=true;
            }
          }).catch(err=>{
            alert('something went wrong')
          });
        }
      } else {
        console.log('empty error')
      }
    }).finally(() => {

    });
  }

  private googleSignInPopup() {
    // [START auth_google_signin_popup]
    firebase.auth()
        .signInWithPopup(this.provider!)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential: firebase.auth.AuthCredential | null = result.credential;

          console.log(result)
          console.log(credential)
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = credential!.providerId;
          // The signed-in user info.
          var user = result.user;
          // ...
        }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
    // [END auth_google_signin_popup]
  }
  async submitgoogle() {
    this.googleSignInPopup();
  }

  public async signInWithCustomToken(token:string){
    let outerThis = this;
    firebase.auth().signInWithCustomToken(token).then(function(res) {
      console.log('all good. Setting state')
      outerThis.cookied=true;
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      alert('ERROR SIGNING IN: ' + errorMessage)
      // ...
    });
  }
  
  async tradeCookie(){
    let customToken = await this.userApi.userManagerGlobalVerifyCookie();
    if (customToken.status==200){
      console.log(customToken.data)
      console.log('got custom token, signing in...');
      this.signInWithCustomToken(customToken.data);
      //will we be stuck in an infinite loop?
    }
  }

  mounted(){
    this.googleProvider();
    console.log('checking for session cookie')
    // let existingCookie = Cookies.get('__session');
    // if (existingCookie) {
    //   //trade
    //   this.tradeCookie()
    // }
  }

  get ButtonImage(){
    if (this.hover){
      return `${this.publicPath}images/btn_google_signin_dark_focus_web.png`
    } else {
      return `${this.publicPath}images/btn_google_signin_dark_normal_web.png`
    }
  }



  getEmptyForm() {
    return {
      username:"",
      password:""
    }
  }


  get formString() {
    return JSON.stringify(this.form, null, 4);
  }

  get isValid() {
    return !this.$v.form.$invalid;
  }

  get isDirty() {
    return this.$v.form.$anyDirty;
  }

  checkIfValid(fieldName: any) {
    const field = this.$v.form[fieldName]
    if (field == undefined) {
      return false;
    }
    return !field.$dirty ? null : !(field.$invalid || field.$model === '');
  }
  validate() {
    this.$v.$touch()
  }

  reset() {
    this.form = this.getEmptyForm()
    this.submitted = false
    this.$v.$reset()
  }

  async submit() { //mock actual login
    try {
      let dto = new LoginDTO(this.form.username, this.form.password);
      vxm.auth.loginNoRedirect(dto).then(
          res=>{
            console.log('Succeeded with ' + res);
            if (res){
              this.$router.push('/dashboard/');
            }
          }
      ).catch(err=>{
        this.showOk2=true;
      });

    } catch (err) {
      console.log('Failed with ' + err);
    }
  }
}

